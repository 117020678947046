import React from "react";
import { FaStar} from "react-icons/fa";
import { FaPlus } from "react-icons/fa";

const FollowingSection = () => {
  return (
    <div className="my-16">

      <div className="flex items-center justify-center mt-12">
        <div className=" sm:w-9/12 items-center justify-center p-4 " >
        </div>
      </div>
      <div className="flex items-center justify-center ">
        <div className="flex flex-wrap w-full gap-10 items-center justify-center  p-4 ">

          <div className="teacher-item p-1 my-5 border-2 border-black  rounded-lg w-64" data-aos="zoom-in-left">
            <div className="flex items-center gap-1 justify-center text-[#5963F5]">
              <p className="text-[36px] font-semibold text-center">1000</p>
              <FaPlus className="text-md" />
            </div>

            <div>
              <p className="text-[24px] font-semibold text-center my-2">Experienced Tutors</p>
            </div>

          </div>

          <div className="teacher-item p-1 my-5 border-2 border-black  rounded-lg w-64" data-aos="zoom-in-left">
            <div className="flex items-center gap-1 justify-center text-[#5963F5]">
              <p className="text-[36px] font-semibold text-center ">7000</p>
              <FaPlus className="text-md" />
            </div>

            <div>
              <p className="text-[24px] font-semibold text-center my-2">Students</p>
            </div>

          </div>

          <div className="teacher-item p-1 my-5 border-2 border-black  rounded-lg w-64" data-aos="zoom-in-left">
            <div className="flex items-center gap-1 justify-center text-[#5963F5]">
              <p className="text-[36px] font-semibold text-center ">120</p>
              <FaPlus className="text-md" />
            </div>

            <div>
              <p className="text-[24px] font-semibold text-center my-2">Subjects</p>
            </div>

          </div>

          <div className="teacher-item p-1 my-5 border-2 border-black  rounded-lg w-64" data-aos="zoom-in-left">
            <div className="text-center ">
              <p className="text-[36px] font-semibold text-center text-[#5963F5]">4/9</p>
              <div className="flex items-center justify-center text-yellow-500">
              <FaStar className=""/>
              <FaStar className=""/>
              <FaStar className=""/>
              <FaStar className=""/>

              </div>
            </div>

            <div>
              <p className="text-[24px] font-semibold text-center ">Subjects</p>
            </div>

          </div>


        </div>
      </div>
    </div>
  );
};

export default FollowingSection;
