import React from 'react'
import DataTable2 from '../../../components/AdminTable/DataTable2';

function Report() {
  const header=[{
    id:1,
    thead1:"Teacher Name",
    thead2: "Subject",
    thead3:"Student Name",
    thead5:"Status",
    
  }]

  const data = [
    {
      id: 1,
      column1: "Irfan Ahsan",
      column2: "Subject Name",
      column3: "110",
      column4: "---",
    },
    {
      id: 2,
      column1: "Muslim Sheikh",
      column2: "Subject Name",
      column3: "50",
      column4: "---",
      
    },
    {
      id: 3,
      column1: "Muhammad Sohail",
      column2: "Subject Name",
      column3: "210",
      column4: "---",
      
    },
    {
      id: 4,
      column1: "Zohaib Bhai",
      column2: "Subject Name",
      column3: "90",
      column4: "---",
   
    },
    {
      id: 5,
      column1: "Muslim Sheikh",
      column2: "Subject Name",
      column3: "140",
      column4: "---",
     
    },
   
    // Add more data objects as needed
  ];



  return (
    <main>
      <DataTable2 
      heading={'Reports'}
      SubHeading={'Teachers'}
      header={header}
      data={data}/>

      
    </main>
  )
}

export default Report
