import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import TeacherCard from "./TeacherCard";
import { FiSearch } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { getHitID } from "../../../config/HitIDStorage";
import axios from "axios";
import apiKey from "../../../config/apiKey";

function OnlineTutoring() {
  const ID = getHitID();
  const admin = JSON.parse(localStorage.getItem("edo-admin"));
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading before data fetch
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/admin/read-notifications/${ID}`,
          {
            headers: {
              Authorization: `Bearer ${admin?.token}`,
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setLoading(false); // Stop loading after data fetch
      }
    };

    fetchData();
  }, [ID,admin?.token]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.student_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.class_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.subjects.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.day_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.start_time.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.duration.toLowerCase().includes(searchTerm.toLowerCase())
  );


   // Time format
   function formatTime(time) {
    let [hours, minutes] = time.split(":");
    let period = "AM";

    hours = parseInt(hours, 10);

    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${period}`;
  }

  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <TeacherCard />
          <div className="flex flex-wrap md:justify-start justify-center space-x-5 border-b-2 text-base font-medium ">
            <NavLink
              to="../teacherDash"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Dashboard
            </NavLink>

            <NavLink
              to=".././teacherMessage"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              View Profile
            </NavLink>

            <NavLink
              to=".././onlineTutoring"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Online Tutoring
            </NavLink>

            <NavLink
              to=".././homeTutoring"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Home Tutoring
            </NavLink>

           <NavLink
              to=".././teacherChat"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Messages
            </NavLink> 

            {/* <NavLink
              to=".././teacherReport"
              className={({ isActive }) =>
                isActive
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : undefined
              }
            >
              Report
            </NavLink> */}
          </div>

      
              <div className="mt-2 p-2">
                <div className="flex flex-wrap items-center justify-between p-2">
                  <div className="flex justify-between">
                    <p className="text-[22px] font-bold">Online Tution</p>
                  </div>
                  <div className="flex items-center">
                    <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
                      <FiSearch className="h-5 w-5 absolute text-[#5963F5]" />
                      <input
                        type="text"
                        className="outline-none pl-8"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                    </div>
                    {/* <div className="border-2 border-[#5963F5] rounded-md px-3 py-2 flex gap-2 ml-4">
                      <FaPlus className="h-5 w-3 item-center fill-[#5963F5]" />
                      <button
                        className="text-[#5963F5] text-[14px] font-semibold"
                        onClick={() => setShowModal(true)}
                      >
                        Create Class
                      </button>
                    </div> */}
                  </div>
                </div>

                <div className="mt-5">
                  <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
                    <thead className="text-sm text-[#B5B7C0] font-medium border-b">
                      <tr className="border-b">
                        <td className="py-5 px-1">Student Name</td>
                        <td className="py-5 px-1">Class</td>
                        <td className="py-5 px-1">Subject</td>
                        <td className="py-5 px-1">Day</td>
                        <td className="py-5 px-1">Timing</td>
                        <td className="py-5 px-1">Duration</td>
                        <td className="py-5 px-1">Payment</td>
                      </tr>
                    </thead>
                    <tbody className="text-black text-sm font-medium">
  {loading ? (
    <tr>
      <td colSpan="9" className="py-5 px-1 text-center">
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
          <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
          <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
            Edolution...
          </p>
        </div>
      </td>
    </tr>
  ) : filteredData.length === 0 ? (
    <tr>
      <td colSpan="9" className="py-5 px-1 text-center">
        <p className="text-gray-500">No Online Tution Available</p>
      </td>
    </tr>
  ) : (
    filteredData.map((item, index) => (
      <tr key={index} className="border-b">
        <td className="py-5 px-1">{item.student_name}</td>
        <td className="py-5 px-1">{item.class_name}</td>
        <td className="py-5 px-1">{item.subjects}</td>
        <td className="py-5 px-1 capitalize">
          {item.day_name ? item.day_name : "Not Define"}
        </td>
        <td className="py-5 px-1">
                            {item.start_time && item.end_time
                              ? `${formatTime(item.start_time)} to ${formatTime(
                                  item.end_time
                                )}`
                              : item.timeslot_id}
                          </td>
        <td className="py-5 px-1">
          {item.days} {item.duration}
        </td>
        <td className="py-5 px-1">Rs.{item.payment}</td>
      </tr>
    ))
  )}
</tbody>

                  </table>
                </div>

                {/* 1-modal */}
                {showModal && (
                  <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                      <div className="relative mx-auto w-1/3">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                          <div className="items-start justify-center p-5 border-solid border-blueGray-200 rounded-t">
                            <button
                              className="ml-auto float-right text-2xl"
                              onClick={() => setShowModal(false)}
                            >
                              <span className="text-[#000]"> × </span>
                            </button>
                          </div>
                          <div className="text-center">
                            <h1 className="text-[24px] font-semibold text-[#000]">
                              Enter Class Details
                            </h1>
                            <p className="text-[12px] font-semibold text-[#2D3748]">
                              Lorem Ipsum is simply dummy text of the printing.
                            </p>
                          </div>
                          <div className="relative p-4 text-start h-60">
                            <div className="text-center my-4">
                              <input
                                type="date"
                                placeholder="Select Date"
                                className="input-date border-2 w-80 px-6 py-2 rounded-md text-gray-700 focus:outline-none focus:border-[#2BACE2] date-icon-color"
                              />
                            </div>
                            <div className="text-center my-4">
                              <select
                                placeholder="Select Subject"
                                name="subject"
                                className="border-2 w-80 px-6 py-2 rounded-md text-gray-700 focus:outline-none focus:border-[#2BACE2]"
                              >
                                <option value="">Select</option>
                                <option value="math">Math</option>
                                <option value="english">English</option>
                              </select>
                            </div>
                            <div className="text-center my-4">
                              <select
                                name="student"
                                className="border-2 w-80 px-6 py-2 rounded-md text-gray-700 focus:outline-none focus:border-[#2BACE2]"
                              >
                                <option value="">Select</option>
                                <option value="sohail">Muhammad Sohail</option>
                                <option value="muslim">Muhammad Muslim</option>
                                <option value="irfan">Irfan Ahmed</option>
                              </select>
                            </div>
                          </div>
                          <div className="text-center mb-7">
                            <button
                              className="font-semibold text-white text-[20px] border-2 rounded-lg bg-[#5963F5] px-6 cursor-pointer py-3"
                              onClick={() => setShowSecondModal(true)}
                            >
                              Generate Link
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                  </>
                )}

                {/* 2-modal */}
                {showSecondModal && (
                  <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                      <div className="relative mx-auto w-1/3">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                          <div className="items-start justify-center p-5 border-solid border-blueGray-200 rounded-t">
                            <button
                              className="ml-auto float-right text-2xl"
                              onClick={() => setShowSecondModal(false)}
                            >
                              <span className="text-[#000]"> × </span>
                            </button>
                          </div>
                          <div className="text-center">
                            <h1 className="text-[24px] font-semibold text-[#000]">
                              Class Zoom Link
                            </h1>
                            <p className="text-[12px] font-semibold text-[#2D3748]">
                              Lorem Ipsum is simply dummy text of the printing.
                            </p>
                          </div>
                          <div className="relative p-4 text-start h-60">
                            <div className="text-center my-4">
                              <h1>Link Here</h1>
                            </div>
                          </div>
                          <div className="text-center mb-7">
                            <button className="font-semibold text-white text-[20px] border-2 rounded-lg bg-[#5963F5] px-6 cursor-pointer py-3">
                              Send the Students
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                  </>
                )}
              </div>
        </div>
      </div>
    </>
  );
}

export default OnlineTutoring;
