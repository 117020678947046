import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SideBar from "../../pages/TeacherDasboard/TeachSideBar/SideBar";
import TopBar from "../../pages/TeacherDasboard/TeachSideBar/TopBar";
import Notification from "../../pages/TeacherDasboard/Notificaion/Notification";
import NewNotification from "../../pages/TeacherDasboard/Notificaion/NewNotification";
import UnreadNotification from "../../pages/TeacherDasboard/Notificaion/UnreadNotification";
import Dashboard from "../../pages/TeacherDasboard/Dashboard/Dashboard";
import OnlineTution from "../../pages/TeacherDasboard/OnlineTution/OnlineTution";
import HomeTution from "../../pages/TeacherDasboard/HomeTution/HomeTution";
import MyStudent from "../../pages/TeacherDasboard/MyStudent/MyStudent";
import TeacherMessage from "../../pages/TeacherDasboard/HomeTution/TeacherMessage";
import TeacherChat from "../../pages/TeacherDasboard/HomeTution/TeacherChat";
import TeacherStartClass from "../../pages/TeacherDasboard/HomeTution/TeacherstartClass";
import TeacherProfile from "../../pages/TeacherDasboard/Home/TeacherProfile";
import TeacherPayment from "../../pages/TeacherDasboard/Home/TeacherPayment";
import PaymentDetail from "../../pages/TeacherDasboard/Home/PaymentDetail";
import ProfileSetting from "../../pages/TeacherDasboard/Setting/ProfileSetting";
import SubjectWP from "../../pages/TeacherDasboard/Setting/SubjectWP";
import OtherDetails from "../../pages/TeacherDasboard/Setting/OtherDetails.jsx";
import SecurityPrivacy from "../../pages/TeacherDasboard/Setting/SecurityPrivacy.jsx";
import TeachNotification from "../../pages/TeacherDasboard/Setting/TeachNotification.jsx";
import AddSubject from "../../pages/TeacherDasboard/Setting/AddSubject.jsx";
import SaveDetail from "../../pages/TeacherDasboard/Setting/SaveDetail.jsx";
import Student from "../../pages/TeacherDasboard/Home/Student.jsx";
import Request from "../../pages/TeacherDasboard/Home/Request.jsx";
import ZoomMeetingPage from "../../pages/TeacherDasboard/Meeting/ZoomMeetingPage.jsx";

function TeacherLayout() {
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const { pathname } = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    if (window.innerWidth <= 1024) {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const user = JSON.parse(localStorage.getItem("edo-teacher"));
    if (!user) {
      navigate("/login/teacherLogin");
    }
  }, [navigate, pathname]);
  return (
    <>
      <TopBar toggleSidebar={toggleSidebar}  totalUnreadCount={totalUnreadCount} />
      <div className="flex">
        <SideBar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <div className="h-screen flex-1 px-2 mt-5">
          <Routes>
            {/* Notification----------------- */}

            <Route path="/notification" element={<Notification />} />
            <Route path="/newNotification" element={<NewNotification />} />
            <Route
              path="/unreadNotification"
              element={<UnreadNotification />}
            />

            {/* TEdit Profile */}
            <Route path="/teacherSetting" element={<TeacherProfile />} />
            <Route path="/students" element={<Student />} />
            <Route path="/request" element={<Request />} />
            <Route path="/teacherPayment" element={<TeacherPayment />} />
            <Route path="/paymentDetail" element={<PaymentDetail />} />

            {/* Dashboard--------------------- */}
            <Route path="" element={<Dashboard />} />

            {/* Home--------------------- */}
            <Route path="/homeProfile" element={<Dashboard />} />

            {/* Online-Tution----------------------------- */}
            <Route path="/onlineTution" element={<OnlineTution />} />

            {/* Home-Tution---------------- */}
            <Route path="/homeTution" element={<HomeTution />} />
            <Route path="/teacherMessage" element={<TeacherMessage />} />
            <Route path="/teacherChat" element={<TeacherChat setTotalUnreadCount={setTotalUnreadCount} />} />
            <Route path="/teacherStartClass" element={<TeacherStartClass />} />

            {/* My-Student------------------- */}
            <Route path="/myStudent" element={<MyStudent />} />
            <Route path="/ZoomMeetingPage"element={<ZoomMeetingPage/>} />

            {/* Setting-------------------------- */}

            <Route path="/profileSettingTech" element={<ProfileSetting />} />
            <Route path="/subjectTech" element={<AddSubject />} />
            <Route path="/otherDetail" element={<OtherDetails />} />
            <Route path="/security" element={<SecurityPrivacy />} />
            <Route path="techerNotiSetting" element={<TeachNotification />} />
            <Route path="addSubject" element={<SubjectWP />} />
            <Route path="saveDetail" element={<SaveDetail />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default TeacherLayout;
