import React from "react";
import Quotes from "../assets/Quotes.png"
import { Link } from "react-router-dom";
import Class61 from "../assets/Group 61.png"
// import Class63 from "../assets/Group 63.png"

const ClassConduct = () => {
  return (
    <div className="bg-[#59F5DE30] p-8 mt-10 ">
      <div className="flex items-center justify-center">
        <div className="flex flex-wrap sm:w-9/12 items-center justify-center p-4 ">
          <p className="text-[40px] small-heading medium-heading font-bold text-[#1E1E1E] text-center" data-aos="fade-left">
            Classes Conducted By {" "}
             <span className="border-b-8 border-[#AC47F5] small-heading">Edolutions</span>
          </p>
        </div>
      </div>
      <div className="flex flex-wrap  items-center justify-center gap-3 p-6 ">
      <Link to="/showTeacher" className="">
        <div className=" w-72 my-5 p-3  text-[20px] font-normal text-black hover:border-2 hover:border-[#1ccc9e] rounded-sm bg-white text-center border" data-aos="flip-left">
          <div className="flex items justify-center "><img className="mr-2" src={Quotes} alt="" /><p>Primary classes</p></div>
        </div>
        </Link>
      <Link to="/showTeacher" className="">
        <div className=" w-72 my-5 p-3  text-[20px] font-normal text-black hover:border-2 hover:border-[#1ccc9e] rounded-sm bg-white text-center border" data-aos="flip-left">
          <div className="flex items justify-center "><img className="mr-2" src={Quotes} alt="" /><p>Secondary Classes</p></div>
        </div>
        </Link>
      <Link to="/showTeacher" className="">
        <div className=" w-72 my-5 p-3  text-[20px] font-normal text-black hover:border-2 hover:border-[#1ccc9e] rounded-sm bg-white text-center border" data-aos="flip-left">
          <div className="flex items justify-center "><img className="mr-2" src={Quotes} alt="" /><p>Matric / Inter</p></div>
        </div>
        </Link>
      <Link to="/showTeacher" className="">
        <div className=" w-72 my-5 p-3  text-[20px] font-normal text-black hover:border-2 hover:border-[#1ccc9e] rounded-sm bg-white text-center border" data-aos="flip-left">
          <div className="flex items justify-center "><img className="mr-2" src={Quotes} alt="" /><p>O Level / Igcse</p></div>
        </div>
        </Link>
      
       

       

        </div>

        <div className="flex flex-wrap  items-center justify-center gap-3 p-6 -mt-14 ">
        <Link to="/showTeacher" className="">
        <div className=" w-72 my-5 p-3  text-[20px] font-normal text-black hover:border-2 hover:border-[#1ccc9e] rounded-sm bg-white text-center border" data-aos="flip-left">
          <div className="flex items justify-center "><img className="mr-2" src={Quotes} alt="" /><p>A Level</p></div>
        </div>
        </Link>
      <Link to="/showTeacher" className="">
        <div className=" w-72 my-5 p-3  text-[20px] font-normal text-black hover:border-2 hover:border-[#1ccc9e] rounded-sm bg-white text-center border" data-aos="flip-left">
          <div className="flex items justify-center "><img className="mr-2" src={Quotes} alt="" /><p>Aptitude Test Prep</p></div>
        </div>
        </Link>
      <Link to="/showTeacher" className="">
        <div className=" w-72 my-5 p-3  text-[20px] font-normal text-black hover:border-2 hover:border-[#1ccc9e] rounded-sm bg-white text-center border" data-aos="flip-left">
          <div className="flex items justify-center "><img className="mr-2" src={Quotes} alt="" /><p>University Courses</p></div>
        </div>
        </Link>

      
      </div>

      {/* <img src={Class61} className='absolute left-[-0px]  hidden xl:block' alt="" /> */}
      {/* <img src={Class63} className='absolute right-[-0px] top-[1600px]  hidden xl:block' alt="" /> */}
    </div>
  );
};

export default ClassConduct;
