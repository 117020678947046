import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { BiCamera } from "react-icons/bi";
import { HiLocationMarker } from "react-icons/hi";
import { toast } from "react-toastify";
import vector from "../../../assets/Vector 01.png";
import { ImLocation } from "react-icons/im";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function HomeTution() {
  const [showModal, setShowModal] = useState(false);
  const [showModalSec, setShowModalSec] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    location: "",
    snap: "",
    teacher_id: "",
    student_id: "",
    ttr_id: "",
  });

  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const userId = teacher ? teacher.teacher.id : "";
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading before data fetch
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/teacher/read-notifications-home/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId, teacher?.token]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const [sortOption, setSortOption] = useState("Newest");
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const sortedData = data.sort((a, b) => {
    if (sortOption === "Newest") {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (sortOption === "Oldest") {
      return new Date(a.created_at) - new Date(b.created_at);
    }
    return 0;
  });


  const filteredData = sortedData.filter(
    (item) =>
      item.student_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.class_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.subjects.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
      teacher_id: userId,
    }));
  };

  const handleStartClass = (studentId, tbl_id) => {
    setFormData((prev) => ({
      ...prev,
      student_id: studentId,
      ttr_id: tbl_id,
    }));
    setShowModal(true);
  };

  const handleSubmit = async () => {
    // Clear previous errors
    setErrors({});

    // Validate the form
    const newErrors = {};
    if (!formData.location) {
      newErrors.location = "Location is required.";
    }
    if (!formData.snap) {
      newErrors.snap = "Snap is required.";
    }

    // If there are errors, don't submit the form
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataToSend.append(key, formData[key]);
      });

      // Sending the request and awaiting the response
      const response = await axios.post(
        `${apiKey}/api/edolutions/create-class`,
        formDataToSend
      );

      // Displaying success toast
      toast.success(response.data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          backgroundColor: "#4FD1C5", // Customize background color
          color: "#fff", // Customize text color
        },
      });

      // Closing modal and resetting form
      setShowModal(false);
      setFormData({
        location: "",
        snap: "",
        teacher_id: userId,
        student_id: "",
        ttr_id: "",
      });
    } catch (error) {
      console.error("Error creating class:", error);
      toast.error("Failed to create class. Please try again.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [ttrId, setttrId] = useState(null);
  const [totalTime, setTotalTime] = useState("");
  const [location, setLocation] = useState("");

  const handleStartClassSec = async (id) => {
    setttrId(id);

    try {
      // Make API call to stop counter
      const response = await axios.post(
        `${apiKey}/api/edolutions/stop-counter/${id}`
      );

      const { total_time, location } = response.data;

      setTotalTime(total_time);
      setLocation(location);
      setShowModalSec(true);
      setDetailModal(false);
    } catch (error) {
      toast.error("Class has already ended or another", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleConfirmEnd = async () => {
    if (ttrId) {
      // Close the modal after confirming
      setShowModalSec(false);
    }
  };

  const [detail, setDetailModal] = useState(false);
  const [studentData, setStudentData] = useState(null);

  const studentDetails = async (id) => {
    setttrId(id);
    // console.log(id);
    try {
      const response = await axios.get(
        `${apiKey}/api/edolutions/create-class-home/${id}`
      );

      const studentData = response.data.data;

      if (!studentData) {
        // Show toast error if no data found
        toast.error("Student class record not found", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      setStudentData(studentData);
      setDetailModal(true);
    } catch (error) {
      toast.error("Student Record Not  found Create class", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // image work
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  // location Work
  // image work
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showModalFour, setShowModalFour] = useState(false);
  const handleLocation = (location) => {
    setSelectedLocation(location);
    setShowModalFour(true); // Show the modal when a location is selected
  };

  // Time format
  function formatTime(time) {
    let [hours, minutes] = time.split(":");
    let period = "AM";

    hours = parseInt(hours, 10);

    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${period}`;
  }

  const [expandedRow, setExpandedRow] = useState(null);

  const handleToggleDetails = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <div className="mt-2">
            <div className="flex flex-wrap items-center justify-between p-2">
              <div className="flex justify-between">
                <p className="text-[22px] font-bold">Home Tution</p>
              </div>
              <div className="flex items-center">
                <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
                  <FiSearch className="h-5 w-5 absolute text-[#5963F5]" />
                  <input
                    type="text"
                    className="outline-none pl-8"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
                <div className="relative ml-2">
                  <select
                    className="border shadow-md rounded-md cursor-pointer px-5 py-2 outline-none pl-2 pr-8 text-sm"
                    value={sortOption}
                    onChange={handleSortChange}
                  >
                    <option value="Newest">Sort By: Newest</option>
                    <option value="Oldest">Sort By: Oldest</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
                <thead className="text-sm text-[#B5B7C0] font-medium border-b">
                  <tr className="border-b">
                    <td className="py-5 px-1">Student Name</td>
                    <td className="py-5 px-1">Class</td>
                    <td className="py-5 px-1">Subject</td>
                    <td className="py-5 px-1">Start Class</td>
                    <td className="py-5 px-1">Classes</td>
                    <td className="py-5 px-1">Details</td>
                  </tr>
                </thead>
                <tbody className="text-black text-sm font-medium">
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="py-5 px-1 text-center">
                        <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                          <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                          <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                            Edolution...
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : paginatedData.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="py-5 px-1 text-center">
                        <p className="text-gray-500">
                          No Home Tutoring Available
                        </p>
                      </td>
                    </tr>
                  ) : (
                    paginatedData.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr className="border-b">
                          <td className="py-5 px-1">{item.student_name}</td>

                          <td className="py-5 px-1">{item.class_name}</td>
                          <td className="py-5 px-1">{item.subjects}</td>
                          <td
                            className="cursor-pointer  "
                            onClick={() =>
                              handleStartClass(item.student_id, item.tbl_id)
                            }
                          >
                            <p className="bg-[#72dad0] text-center w-fit px-5 rounded-lg py-1">
                              Start
                            </p>
                          </td>
                          <td
                            className="cursor-pointer"
                            onClick={() => studentDetails(item.tbl_id)}
                          >
                            <p className="bg-slate-200 text-center w-fit px-5 rounded-lg py-1">
                              View
                            </p>
                          </td>

                          <td className="py-5 px-1">
                            <button
                              className="text-blue-500 underline"
                              onClick={() => handleToggleDetails(index)}
                            >
                              {expandedRow === index ? (
                                <>
                                  <AiOutlineEyeInvisible className="text-lg text-[#72dad0]" />{" "}
                                </>
                              ) : (
                                <>
                                  <AiOutlineEye className="text-lg text-[#72dad0]" />
                                </>
                              )}
                            </button>
                          </td>
                        </tr>
                        {expandedRow === index && (
                          <tr>
                            <td colSpan="6">
                              <table className="w-full text-sm text-left text-gray-500 rounded-lg  shadow-lg table-auto ">
                                <thead className="text-sm text-white  bg-[#72dad0]  font-medium">
                                  <tr>
                                    <td className="py-3 px-1">Day</td>
                                    <td className="py-3 px-1">Timing</td>
                                    <td className="py-3 px-1">Duration</td>
                                    <td className="py-3 px-1">Payment</td>
                                    <td className="py-3 px-1">Location</td>
                                  </tr>
                                </thead>
                                <tbody className="text-black text-sm font-medium">
                                  <tr className="">
                                    <td className="py-5 px-1 capitalize">
                                      {item.day_name
                                        ? item.day_name
                                        : "Not Defined"}
                                    </td>
                                    <td className="py-5 px-1">
                                      {item.start_time && item.end_time
                                        ? `${formatTime(
                                            item.start_time
                                          )} to ${formatTime(item.end_time)}`
                                        : item.timeslot_id}
                                    </td>
                                    <td className="py-5 px-1">
                                      {item.days} {item.duration}
                                    </td>
                                    <td className="py-5 px-1">
                                      Rs.{item.payment}
                                    </td>
                                    <td
                                      className="py-5 px-1"
                                      onClick={() =>
                                        handleLocation(item.location)
                                      }
                                    >
                                      <ImLocation className="text-[#72dad0] text-lg" />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex flex-wrap gap-5 justify-between items-center w-full text-sm mt-4 pb-4 px-2">
              <div className="flex items-center gap-x-2 text-gray-400">
                <span>
                  <select
                    className="text-sm border-none text-black bg-[#F5F5F5] rounded-lg"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    {[10, 20, 30, 40, 50, 100].map((perPage) => (
                      <option key={perPage} value={perPage}>
                        {perPage}
                      </option>
                    ))}
                  </select>
                </span>
                <span>Showing Data</span>
                <span>
                  {startIndex + 1}-{endIndex} of {totalItems} Entries
                </span>
              </div>
              <div className="flex items-center gap-x-2 text-gray-400">
                <button
                  className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  <MdKeyboardArrowLeft />
                </button>
                {[...Array(totalPages)].map((_, i) => (
                  <button
                    key={i}
                    className={`text-base text-black px-2 py-1 rounded-lg ${
                      currentPage === i + 1
                        ? "bg-[#4FD1C5] text-white"
                        : "bg-[#F5F5F5]"
                    }`}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
                <span>of {totalPages || 1} pages</span>
                <button
                  className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <MdKeyboardArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal---------------------------- */}
      <div>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
              <div className="relative xl:mx-auto lg:mx-5 xl:w-1/2 lg:w-9/12  ">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                  {/*header*/}

                  {/*body*/}
                  <div className="relative text-start">
                    <div>
                      <div className="md:flex items-center sm:block">
                        <div className="flex flex-col justify-center items-center mx-auto order-2 md:order-1 py-12 md:w-1/2 w-auto text-center">
                          <div>
                            <p className="text-2xl font-medium">
                              Enter Class Details
                            </p>
                            <p className="text-xs font-light">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                            </p>
                          </div>
                          <div className="border rounded-md mt-5 bg-[rgb(187,222,251,0.1)] flex py-2 items-center gap-4 px-5 w-auto">
                            <HiLocationMarker className="text-[#4FD1C5]" />
                            <input
                              type="text"
                              name="location"
                              value={formData.location}
                              onChange={handleChange}
                              className="bg-transparent text-sm placeholder-[#4FD1C5] outline-none"
                              placeholder="Location Here"
                            />
                          </div>
                          {errors.location && (
                            <p className="text-red-500 text-sm mt-2">
                              {errors.location}
                            </p>
                          )}
                          <div className="border mt-5 rounded-md bg-[rgb(187,222,251,0.1)] flex items-center gap-4 px-5 py-2 w-auto">
                            <input
                              type="file"
                              name="snap"
                              id="uploadBtn"
                              onChange={handleChange}
                              className="w-full border rounded-md p-2"
                            />
                            <label htmlFor="uploadBtn" className=" select-none	">
                              <BiCamera className="cursor-pointer text-[#4FD1C5] " />
                            </label>
                          </div>
                          {errors.snap && (
                            <p className="text-red-500 text-sm mt-2">
                              {errors.snap}
                            </p>
                          )}
                          <p
                            onClick={handleSubmit}
                            className="bg-[#5963F5] text-white rounded-md px-7 mt-5 py-2"
                          >
                            Confirm
                          </p>
                        </div>
                        <div className="md:w-1/2 order-1 md:order-2">
                          <button
                            className="absolute right-0 p-3 ml-auto md:flex hidden float-right sm:mt-0 "
                            onClick={() => setShowModal(false)}
                          >
                            <span className="text-[#000] bg-white text-3xl px-1">
                              {" "}
                              ×{" "}
                            </span>
                          </button>
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d924237.7096542217!2d66.49595908273504!3d25.192983663770796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e06651d4bbf%3A0x9cf92f44555a0c23!2sKarachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1713914556658!5m2!1sen!2s"
                            className="sm:h-[450px] h-[300px] w-full"
                            title="map"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="fast"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>

      {/* Second modal */}
      <div>
        {showModalSec ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
              <div className="relative xl:mx-auto lg:mx-5 xl:w-1/2 lg:w-9/12">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                  {/*header*/}

                  {/*body*/}
                  <div className="relative text-start">
                    <div>
                      <div className="md:flex items-center sm:block">
                        <div className="flex flex-col justify-center items-center mx-auto order-2 md:order-1 py-12 md:w-1/2 w-auto text-center">
                          <div className="mb-4">
                            <div className="flex justify-center">
                              <img src={vector} alt="" />
                            </div>
                            <p className="text-2xl font-bold">Class Details</p>
                          </div>
                          <div className="font-semibold text-black text-start">
                            <h1>Time: 1 hours</h1>
                            <h1>Location: {location}</h1>
                            <h1>Snap</h1>
                            <h1>Total Time: {totalTime}</h1>
                          </div>

                          <div className="mt-8">
                            <button
                              className="bg-[#5963F5] text-white rounded-md px-7 mt-5 py-2"
                              onClick={handleConfirmEnd}
                            >
                              Confirm to End
                            </button>
                          </div>
                        </div>
                        <div className="md:w-1/2 order-1 md:order-2">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d924237.7096542217!2d66.49595908273504!3d25.192983663770796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e06651d4bbf%3A0x9cf92f44555a0c23!2sKarachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1713914556658!5m2!1sen!2s"
                            className="sm:h-[450px] h-[300px] w-full"
                            title="map"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="fast"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>

      {/* Third Modal */}
      <div>
        {detail ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative xl:mx-auto lg:mx-5 xl:w-1/2 lg:w-9/12">
                {/* Content */}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none overflow-y-auto shiftmodel">
                  {/* Header */}
                  <div className="flex justify-between items-center p-4 border-b border-gray-200">
                    <h3 className="text-xl font-semibold">
                      Student Classes Details
                    </h3>
                    <button
                      className="text-[#000] bg-white text-3xl px-1"
                      onClick={() => setDetailModal(false)}
                    >
                      ×
                    </button>
                  </div>

                  {/* Body */}
                  <div className="relative p-4">
                    {/* Table */}
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Snap
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Location
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Start Time
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            End Time
                          </th>

                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            End Class
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {studentData && studentData.length > 0 ? (
                          studentData.map((student) => (
                            <tr key={student.id}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <img
                                  src={`${apiKey}/homeSnap/${student.snap}`}
                                  alt=""
                                  className="inline-block mr-2 h-12 w-12 object-cover cursor-pointer"
                                  onClick={() =>
                                    handleImageClick(
                                      `${apiKey}/homeSnap/${student.snap}`
                                    )
                                  }
                                />
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {student.location}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {student.start_time}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {student.end_time}
                              </td>
                              <td
                                onClick={() =>
                                  handleStartClassSec(student.ttr_id)
                                }
                                className="px-6 py-4 whitespace-nowrap text-sm cursor-pointer"
                              >
                                <p className="bg-red-300 text-center w-fit px-5 rounded-lg py-1">
                                  End
                                </p>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="3"
                              className="px-6 py-4 text-sm text-gray-500 text-center"
                            >
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}

        {/* Image Modal */}
        {selectedImage && (
          <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-75">
            <div className="relative">
              <img
                src={selectedImage}
                alt="Selected Snap"
                className="max-w-full max-h-screen object-contain"
              />
              <button
                className="absolute top-0 right-0 m-4 text-white text-3xl"
                onClick={closeImageModal}
              >
                ×
              </button>
            </div>
          </div>
        )}
      </div>

      <div>
        {showModalFour ? (
          <>
            {/* Modal Background */}
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>

            {/* Modal Container */}
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="relative bg-white rounded-lg shadow-lg w-full max-w-md mx-4">
                {/* Close Button */}
                <button
                  className="absolute top-2 right-2 text-2xl font-bold text-gray-500 hover:text-gray-700"
                  onClick={() => setShowModalFour(false)}
                  aria-label="Close Modal"
                >
                  ×
                </button>

                {/* Modal Body */}
                <div className="p-6 text-center">
                  <p className="text-lg">Location: {selectedLocation}</p>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default HomeTution;
