import React from "react";
import teacher1 from "../assets/salman.png";
import teacher2 from "../assets/saad.png";
import teacher3 from "../assets/Ahsan.png";
import teacher4 from "../assets/alizanadeem.png";
import teacher5 from "../assets/aqsanaseem.png";
import teacher6 from "../assets/umaimatanveer.png";
import { Link } from "react-router-dom";


const LearningPotential = () => {
  return (
    <div className="my-24 ">

      <div className="flex items-center justify-center mt-12">
        <div className="flex-wrap sm:w-9/12 items-center justify-center p-4 " >
          <p className="text-[40px] font-bold text-[#1E1E1E] text-center small-heading medium-heading" data-aos="fade-down">
          Shaping Futures Unlock Learning Potential
          </p>
          <p className="text-[20px]  text-center text-[#1E1E1E] small-text" data-aos="fade-up">
          Connect with Top Educators on Our Platform For Expert Guidance 
          </p>
        </div>
      </div>

      <div className="flex items-center justify-center mt-5">
        <div className="flex flex-wrap w-full gap-10 items-center justify-center p-4  mt-5">
          <div className="teacher-item my-10  pl-12  pr-12" data-aos="zoom-in-left">
            <div>
              <img className="w-64" src={teacher4} alt="" />
              <p className="text-[20px] font-semibold text-center mt-3">Aliza Nadeem</p>
            </div>
          </div>

          <div className="teacher-item my-10 border-l pl-16 border-r pr-16" data-aos="zoom-in-left">
            <div>
              <img className="w-64" src={teacher2} alt="" />
              <p className="text-[20px] font-semibold text-center mt-3">
                Sir Saad Moten
              </p>
            </div>
          </div>

         
          <div className="teacher-item my-10 pl-12 pr-12" data-aos="zoom-in-left">
            <div>
              <img className="w-64" src={teacher6} alt="" />
              <p className="text-[20px] font-semibold text-center mt-3">Umaima Tanveer Shaikh</p>
            </div>
          </div>
          
          
        </div>
      </div>

        <div className="flex items-center justify-center mt-5">
        <div className="flex flex-wrap w-full gap-10 items-center justify-center p-4  mt-5">
          <div className="teacher-item my-10  pl-12  pr-12" data-aos="zoom-in-left">
            <div>
              <img className="w-64" src={teacher1} alt="" />
              <p className="text-[20px] font-semibold text-center mt-3">Sir Salman</p>
            </div>
          </div>

          <div className="teacher-item my-10 border-l pl-16 border-r pr-16" data-aos="zoom-in-left">
            <div>
              <img className="w-64" src={teacher5} alt="" />
              <p className="text-[20px] font-semibold text-center mt-3">
                Aqsa Naseem
              </p>
            </div>
          </div>

         
          <div className="teacher-item my-10 pl-12 pr-12" data-aos="zoom-in-left">
            <div>
              <img className="w-64" src={teacher3} alt="" />
              <p className="text-[20px] font-semibold text-center mt-3">Sir Ahsan</p>
            </div>
          </div>
          
          
        </div>
      </div>

      <div className="flex justify-center items-center mt-5">
       
        <Link to='/showTeacher'> <button className="bg-[#1ccc9e] text-white px-4 py-2 rounded-md ">View more </button></Link>
      </div>

    
    </div>
  );
};

export default LearningPotential;
