import React  from "react";
import Boards from "../components/Boards";
import Personalize from "../components/Personalize";
import LearningPotential from "../components/LearningPotential";
import ClassConduct from "../components/ClassConduct";
import FindLandingCard from "../components/FindLandingCard";
import FollowingSection from "../components/FollowingSection";

const FindTeacher = () => {

 
  return (
    <div>
      <FindLandingCard />
      <ClassConduct />
      <LearningPotential />
      <FollowingSection />
      <Boards />
      <Personalize link="/login/studentSignUp"/>
    </div>
  );
};

export default FindTeacher;
