import React from 'react'
import FormLogin from '../../components/FormLogin'
import Login2 from "../../assets/Login2.png";

const TeacherLogin = () => {
  return (
    <div>
      <FormLogin
        Description="Continue managing your classes, tracking progress, and accessing valuable resources seamlessly. Empower yourself with the tools you need to guide your students towards success."
        Image={Login2}
        path='.././teacherSignUp'
        forgetpath='.././forgetPass'
      />
    </div>
  )
}

export default TeacherLogin
