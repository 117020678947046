import React, { useState, useEffect } from "react";
import landingContent from "../assets/landingContent.png";
import "../App.css";

const LandingCard = () => {
  const [wordIndex, setWordIndex] = useState(0);
  const words = ["Online", "And", "Home"];

  useEffect(() => {
    const interval = setInterval(() => {
      setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1500);

    return () => clearInterval(interval);
  }, [words.length]);
  // console.log(wordIndex)

  return (
    <div className="my-28">
      <div className="relative flex items-center justify-center px-8">
        <div className="flex flex-wrap  max-w-6xl   items-center justify-between ">
          <div className="w-full lg:w-5/12 md:w-5/12 sm:w-full">
            <img
              className="float-center"
              data-aos="fade-left"
              src={landingContent}
              alt=""
            />
          </div>
          <div
            className="w-full lg:w-7/12 -mr-[14px] md:w-7/12 sm:w-full"
            data-aos="fade-up"
          >
            <div>
              <p className="text-[48px] font-bold text-[#1E1E1E] leading-[50px] small-heading medium-heading">
                Connect With Tutors <br />
                For Tailored Learning{" "}
              </p>
              <span className="small-heading medium-heading text-[48px] font-bold  text-transparent bg-clip-text bg-gradient-to-r from-[#4FD1C5]  to-[#AC47F5]">
                Online And At Home
              </span>
            </div>

            <div>
              <p
                className="text-[20px] small-text  font-normal text-[#1E1E1E] mt-2"
                data-aos="fade-up"
              >
                Edolutions is your go-to platform for connecting with 500+
                qualified tutors to help you achieve your full academic
                potential! <br /> Sign up today to learn more.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingCard;
