import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import apiKey from "../../../config/apiKey";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function MyStudent() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const teacher = JSON.parse(localStorage.getItem("edo-teacher"));
  const userId = teacher ? teacher.teacher.id : "";
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    setLoading(true); // Start loading before data fetch
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiKey}/api/edolutions/teacher/read-notifications-all/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${teacher?.token}`,
            },
          }
        );
        setData(response.data.data); // Assuming your API returns the data in this format
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setLoading(false); 
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId,teacher?.token]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const [sortOption, setSortOption] = useState("Newest");
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const sortedData = data.sort((a, b) => {
    if (sortOption === "Newest") {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (sortOption === "Oldest") {
      return new Date(a.created_at) - new Date(b.created_at);
    }
    return 0;
  });

  const filteredData = sortedData.filter(
    (item) =>
      item.student_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.class_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.subjects.toLowerCase().includes(searchTerm.toLowerCase()) 
  );

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const paginatedData = filteredData.slice(startIndex, endIndex);

    // Time format
  function formatTime(time) {
    let [hours, minutes] = time.split(":");
    let period = "AM";

    hours = parseInt(hours, 10);

    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${period}`;
  }

  const [expandedRow, setExpandedRow] = useState(null);

  const handleToggleDetails = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  return (
    <>
      <div className="flex items-center xl:p-5 sm:p-0 justify-center">
        <div className="w-full border rounded-3xl shadow-xl p-5">
          <div className="mt-2">
            <div className="flex flex-wrap items-center justify-between p-2">
              <div className="flex justify-between">
                <p className="text-[22px] font-bold">My Students</p>
              </div>
              <div className="flex items-center">
                <div className="relative border shadow-md rounded-md flex items-center px-5 py-2">
                  <FiSearch className="h-5 w-5 absolute text-[#5963F5]" />
                  <input
                    type="text"
                    className="outline-none pl-8"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
                <div className="relative ml-2">
                  <select
                    className="border shadow-md rounded-md cursor-pointer px-5 py-2 outline-none pl-2 pr-8 text-sm"
                    value={sortOption}
                    onChange={handleSortChange}
                  >
                    <option value="Newest">Sort By: Newest</option>
                    <option value="Oldest">Sort By: Oldest</option>
                  </select>
                </div>
              </div>

            </div>
            <div className="mt-5">
             

              <table className="w-full text-sm text-left text-gray-500 border-b table-auto">
                <thead className="text-sm text-[#B5B7C0] font-medium border-b">
                  <tr className="border-b">
                    <td className="py-5 px-1">Student Name</td>
                    <td className="py-5 px-1">Class</td>
                    <td className="py-5 px-1">Subject</td>
                    <td className="py-5 px-1">Type</td>
                    <td className="py-5 px-1">Details</td>
                  </tr>
                </thead>
                <tbody className="text-black text-sm font-medium">
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="py-5 px-1 text-center">
                        <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
                          <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
                          <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
                            Edolution...
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : paginatedData.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="py-5 px-1 text-center">
                        <p className="text-gray-500">
                          No Home Tutoring Available
                        </p>
                      </td>
                    </tr>
                  ) : (
                    paginatedData.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr className="border-b">
                          <td className="py-5 px-1">{item.student_name}</td>

                          <td className="py-5 px-1">{item.class_name}</td>
                          <td className="py-5 px-1">{item.subjects}</td>
                          <td className="py-5 px-1 capitalize">{item.type_name}</td>
                         

                          <td className="py-5 px-1">
                            <button
                              className="text-blue-500 underline"
                              onClick={() => handleToggleDetails(index)}
                            >
                              {expandedRow === index ? (
                                <>
                                  <AiOutlineEyeInvisible className="text-lg text-[#72dad0]" />{" "}
                                </>
                              ) : (
                                <>
                                  <AiOutlineEye className="text-lg text-[#72dad0]" />
                                </>
                              )}
                            </button>
                          </td>
                        </tr>
                        {expandedRow === index && (
                          <tr>
                            <td colSpan="6">
                              <table className="w-full text-sm text-left text-gray-500 rounded-lg  shadow-lg table-auto ">
                                <thead className="text-sm text-white  bg-[#72dad0]  font-medium">
                                  <tr>
                                    <td className="py-3 px-1">Day</td>
                                    <td className="py-3 px-1">Timing</td>
                                    <td className="py-3 px-1">Duration</td>
                                    <td className="py-3 px-1">Payment</td>
                                   
                                  </tr>
                                </thead>
                                <tbody className="text-black text-sm font-medium">
                                  <tr className="">
                                    <td className="py-5 px-1 capitalize">
                                      {item.day_name
                                        ? item.day_name
                                        : "Not Defined"}
                                    </td>
                                    <td className="py-5 px-1">
                                      {item.start_time && item.end_time
                                        ? `${formatTime(
                                            item.start_time
                                          )} to ${formatTime(item.end_time)}`
                                        : item.timeslot_id}
                                    </td>
                                    <td className="py-5 px-1">
                                      {item.days} {item.duration}
                                    </td>
                                    <td className="py-5 px-1">
                                      Rs.{item.payment}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex flex-wrap gap-5 justify-between items-center w-full text-sm mt-4 pb-4 px-2">
              <div className="flex items-center gap-x-2 text-gray-400">
                <span>
                  <select
                    className="text-sm border-none text-black bg-[#F5F5F5] rounded-lg"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    {[10, 20, 30, 40, 50, 100].map((perPage) => (
                      <option key={perPage} value={perPage}>
                        {perPage}
                      </option>
                    ))}
                  </select>
                </span>
                <span>Showing Data</span>
                <span>
                  {startIndex + 1}-{endIndex} of {totalItems} Entries
                </span>
              </div>
              <div className="flex items-center gap-x-2 text-gray-400">
                <button
                  className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  &lt;
                </button>
                <div className="flex items-center gap-x-2 text-gray-400">
                  {[...Array(Math.min(5, totalPages || 1))].map((_, index) => {
                    const pageNumber = Math.max(currentPage - 2 + index, 1);
                    const isActive = pageNumber === currentPage;
                    return (
                      <button
                        key={pageNumber}
                        className={`text-base rounded-lg px-3 py-1 ${
                          isActive
                            ? "text-white bg-[#4FD1C5]"
                            : "text-black bg-[#F5F5F5]"
                        }`}
                        onClick={() => handlePageChange(pageNumber)}
                        disabled={pageNumber < 1 || pageNumber > totalPages}
                      >
                        {pageNumber}
                      </button>
                    );
                  })}
                  <span>of {totalPages || 1} pages</span>
                </div>
                <button
                  className="text-base text-black bg-[#F5F5F5] rounded-lg px-2 py-1"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyStudent;
