import React, { useState, useEffect } from "react";
import image from "../assets/TFINDTEXH.png";
import "../App.css";

const FindLandingCard = () => {
  return (
    <div>
      <div className="flex items-center justify-center my-[120px]  ">
        <div className="flex flex-wrap   max-w-6xl  items-center justify-center ">
          <div
            className="w-full lg:w-5/12 md:w-5/12 sm:w-full "
            data-aos="fade-right"
          >
            <img src={image} alt="" />
          </div>
          <div className="w-full lg:w-7/12 md:w-7/12 sm:w-full ">
            <p
              className="text-[48px] small-heading medium-heading font-bold leading-[53px] text-[#1E1E1E]"
              data-aos="fade-up"
            >
              Looking For A Tutor? <br />
              <span className="small-heading text-transparent bg-clip-text bg-gradient-to-r from-[#4FD1C5]  to-[#AC47F5]">
                We’ve Got You Covered!
              </span>
            </p>

            <p
              className="text-[20px] small-text font-normal leading-[27px] text-[#1E1E1E] mt-2"
              data-aos="fade-down"
            >
              At Edolutions, we have a wide selection of teachers available for
              you to choose from based on your specific needs. Sign up today to
              start learning!
            </p>
          </div>
        </div>
      </div>

      {/* <img
        src={Vector50}
        className="absolute right-[-0px] top-[480px]  hidden xl:block"
        alt=""
      /> */}
    </div>
  );
};

export default FindLandingCard;
