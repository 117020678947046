import React, { useEffect, useState } from "react";
import axios from "axios";
import { FiMapPin } from "react-icons/fi";
import { BiArrowBack, BiStar } from "react-icons/bi";
import { MdOutlineGridView, MdViewList } from "react-icons/md";
import { NavLink, useNavigate, Link } from "react-router-dom";
import apiKey from "../config/apiKey";

const ShowAllTeachers = () => {
  const [isGridView, setIsGridView] = useState(true);
  const [teachers, setTeachers] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [classes, setClasses] = useState([]); // State for classes
  const [mySubjects, setMySubjects] = useState([]); // State for subjects
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Filter states
  const [typeFilter, setTypeFilter] = useState(""); // Online/Home/Both
  const [classFilter, setClassFilter] = useState("");
  const [subjectFilter, setSubjectFilter] = useState("");
  const [fee, setFee] = useState(5000); // Single slider for fee
  const [searchTerm, setSearchTerm] = useState(""); // Search state

  const [currentPage, setCurrentPage] = useState(1); // Pagination
  const teachersPerPage = 12; // Show 12 teachers per page

  const student = JSON.parse(localStorage.getItem("edo-student"));

  useEffect(() => {
    setLoading(true);

    // Fetch teachers data
    axios
      .get(`${apiKey}/api/edolutions/teachers/all`, {})
      .then((response) => {
        const data = response.data.data;
        setTeachers(data);
        setFilteredTeachers(data); // Initialize with all teachers
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("No Teacher found related to your selected subject");
        setLoading(false);
      });

    // Fetch classes data
    axios
      .get(`${apiKey}/api/edolutions/classes`, {})
      .then((res) => {
        setClasses(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // Fetch subjects when class is selected
  const handleClassChange = (e) => {
    const selectedClassName = e.target.value; // Get the class name for filtering
    const selectedClassId =
      e.target.options[e.target.selectedIndex].getAttribute("data-classid"); // Get class ID for fetching subjects

    setClassFilter(selectedClassName); // Use class name for filtering

    // Fetch relevant subjects for the selected class
    axios
      .get(`${apiKey}/api/edolutions/classes/${selectedClassId}`, {})
      .then((res) => {
        setMySubjects(res.data);
      })
      .catch((err) => {
        console.error("Error fetching subjects:", err);
      });
  };

  // Filter logic
  const applyFilters = () => {
    let filtered = [...teachers];

    if (typeFilter) {
      filtered = filtered.filter((teacher) => teacher.type_name === typeFilter);
    }

    if (classFilter) {
      filtered = filtered.filter((teacher) =>
        teacher.subjects.some((subject) => subject.class_name === classFilter)
      );
    }

    if (subjectFilter) {
      filtered = filtered.filter((teacher) =>
        teacher.subjects.some((subject) => subject.subject === subjectFilter)
      );
    }

    filtered = filtered.filter((teacher) => {
      const minCharge = Math.max(
        ...teacher.subjects.map((subject) => subject.home_charges)
      );
      return minCharge <= fee;
    });

    // Apply search term filter
    if (searchTerm) {
      filtered = filtered.filter((teacher) =>
        teacher.teacher_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredTeachers(filtered);
  };

  // Toggle view between Grid and List
  const toggleView = () => {
    setIsGridView(!isGridView);
  };

  useEffect(() => {
    applyFilters();
  }, [typeFilter, classFilter, subjectFilter, fee, searchTerm]);

  const navigate = useNavigate();

  // Pagination logic
  const indexOfLastTeacher = currentPage * teachersPerPage;
  const indexOfFirstTeacher = indexOfLastTeacher - teachersPerPage;
  const currentTeachers = filteredTeachers.slice(0, indexOfLastTeacher);

  const loadMoreTeachers = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="relative flex flex-col break-words w-[90%] mx-auto mb-6  bg-white rounded-lg p-8 border-0">
      <div className="mx-auto mb-20">
        <p className="text-2xl md:text-4xl font-extrabold text-black align-center">
          Meet Our Expert Educators Shaping Bright Futures
        </p>
        <p className="my-4 md:text-center">
          Dedicated Professionals Committed To Your Learning Journey
        </p>
      </div>

      {/* Search Input */}

      {/* Filters Section */}
      <div className="flex mx-auto flex-wrap gap-3 md:gap-9  mb-8 items-center mt-5">
        {/* Type Filter */}
        <select
          onChange={(e) => setTypeFilter(e.target.value)}
          className="border px-3 py-2 rounded-md"
        >
          <option value="">Select Type</option>
          <option value="online">Online</option>
          <option value="home">Home</option>
          <option value="both">Both</option>
        </select>

        {/* Class Filter */}
        <select
          onChange={handleClassChange}
          className="border px-3 py-2 rounded-md"
        >
          <option value="">Select Class</option>
          {classes.map((classItem) => (
            <option
              key={classItem.id}
              value={classItem.class_name} // Use class_name for filtering
              data-classid={classItem.id} // Store class_id for fetching subjects
            >
              {classItem.class_name}
            </option>
          ))}
        </select>

        {/* Subject Filter */}
        <select
          onChange={(e) => setSubjectFilter(e.target.value)}
          className="border w-64 px-3 py-2 rounded-md"
        >
          <option value="">Select Subject</option>
          {mySubjects.map((subject) => (
            <option key={subject.id} value={subject.subject_name}>
              {subject.subject_name}
            </option>
          ))}
        </select>

        {/* Fee Range Filter */}
      </div>
      <div className="mb-4 mx-auto w-full flex flex-wrap  justify-around gap-4 max-w-3xl">
      
        <div className="flex items-center gap-2">
          <p>Fees </p>
          <input
            type="range"
            min="500"
            step="500"
            max="5000"
            value={fee}
            onChange={(e) => setFee(e.target.value)}
            style={{
              background: `linear-gradient(90deg, #1ccc9e ${
                ((fee - 500) / 4500) * 100
              }%, #ddd ${((fee - 500) / 4500) * 100}%)`,
              WebkitAppearance: "none", // To remove default styles for WebKit browsers
              width: "100%",
              height: "8px",
              borderRadius: "5px",
              outline: "none",
            }}
            className="range-slider"
          />
          <p>Up to Rs. {fee}</p>
        </div>
        <div>
        <input
          type="text"
          placeholder="Search for a teacher..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 px-4 py-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-[#1ccc9e]"
        /></div>

      </div>

      {/* Loader */}
      {loading ? (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-200 bg-opacity-5 backdrop-blur-sm z-50">
          <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-solid rounded-full animate-spin border-t-[#2DD0A5]"></div>
          <p className="mt-4 text-lg font-semibold text-[#2dd0a5]">
            Edolution...
          </p>
        </div>
      ) : filteredTeachers.length === 0 ? (
        <div>No teachers found matching the criteria.</div>
      ) : (
        <div
          className={
            isGridView
              ? "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mx-auto mt-5"
              : "flex flex-col gap-6 justify-center items-center"
          }
        >
          {currentTeachers.map((teacher) => (
            <div key={teacher.id} className="">
              <Link to={`/Teacherprof/${teacher.user_id}`}>
                <div className="bg-white shadow-md w-56 rounded-lg h-full overflow-hidden flex flex-col justify-start pt-1  items-center cursor-pointer">
                  <img
                    src={
                      teacher.photo
                        ? `${apiKey}/userProfile/${teacher.photo}`
                        : `${apiKey}/userProfile/87654321.png`
                    }
                    alt={teacher.teacher_name || "Teacher profile picture"}
                    className="rounded-full border w-24 h-24"
                  />
                  <div className="p-4 text-center">
                    <h3 className="text-lg font-semibold capitalize ">
                      {teacher.teacher_name}
                    </h3>

                    <div className="flex items-center justify-center mt-2">
                      <BiStar className="h-4 w-4 fill-yellow-500 text-yellow-500" />
                      <BiStar className="h-4 w-4 fill-yellow-500 text-yellow-500" />
                      <BiStar className="h-4 w-4 fill-yellow-500 text-yellow-500" />
                    </div>

                    <Link
                      to={`/Teacherprof/${teacher.user_id}`}
                      className="text-[#1ccc9e] text-sm mt-3"
                    >
                      View Profile
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}

      {/* Load More Button */}
      {currentTeachers.length < filteredTeachers.length && (
        <div className="mt-5 text-center">
          <button
            onClick={loadMoreTeachers}
            className="px-4 py-2 bg-[#2dd0a5] text-white rounded-md"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default ShowAllTeachers;
