import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import { RiMenu2Fill } from "react-icons/ri";

function App() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  const isActive = (path) => {
    if (location.pathname === "/" && path === "/work") return true;
    return location.pathname === path;
  };

  return (
    <nav className="fixed top-0 left-0 right-0 bg-white shadow-md z-10 py-3">
      <div className="container mx-auto flex flex-wrap items-center justify-between px-6 py-4">
        <div className="flex-shrink-0 flex justify-between w-full lg:w-auto">
          <Link to="/">
            <img className="w-[256px] h-[43.78px]" src={logo} alt="Logo" />
          </Link>
          <div className="block lg:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="flex text-3xl items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
            >
              <RiMenu2Fill />
            </button>
          </div>
        </div>

        <div
          className={`lg:flex lg:items-center lg:justify-between flex-grow ${
            isOpen ? "block" : "hidden"
          } lg:block`}
        >
          <div className="text-[18px] font-semibold flex flex-col lg:flex-row lg:space-x-4 lg:items-center lg:justify-center flex-grow">
            <Link
              to="/work"
              className={`block mt-4 lg:mt-0 ${isActive("/work") ? "font-bold" : "text-[#1E1E1E]"}`}
            >
              How it Works
            </Link>
            <Link
              to="/findTeacher"
              className={`block mt-4 lg:mt-0 ${isActive("/findTeacher") ? "font-bold" : "text-[#1E1E1E]"}`}
            >
              Find Teacher
            </Link>
            <Link
              to="/job"
              className={`block mt-4 lg:mt-0 ${isActive("/job") ? "font-bold" : "text-[#1E1E1E]"}`}
            >
              Find Teacher Job
            </Link>
            <Link
              to="/aboutUs"
              className={`block mt-4 lg:mt-0 ${isActive('/aboutUs') ? 'font-bold ' : 'text-[#1E1E1E]'}`}
            >
              About Us
            </Link>
            {/* <Link
              to="/aboutUs"
              className={`block mt-4 lg:mt-0 ${isActive('/aboutUs') ? 'font-bold text-[#5963F5]' : 'text-[#1E1E1E]'}`}
            >
              Contact Us
            </Link> */}
          </div>

          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-center mt-4 lg:mt-0">
            <select className="w-16 font-semibold cursor-pointer mb-2 lg:mb-0 lg:mr-2">
              <option value="">PAK</option>
              <option value="">USA</option>
              <option value="">UK</option>
              <option value="">Dubai</option>
              <option value="">Saudi Arabia</option>
              <option value="">Oman</option>
              <option value="">Qatar</option>
              <option value="">Bahrain</option>
              <option value="">Australia</option>
              <option value="">China</option>
              <option value="">Canada</option>
            </select>

            <div className="flex lg:flex-row mt-4 lg:mt-0">
              <Link to="/login/loginPage/Login">
                <button className="text-[#5963F5] font-bold text-md py-2 px-6 mb-2 lg:mb-0 lg:mr-2">
                  Login
                </button>
              </Link>
              <Link to="/login/loginPage/Signup">
                <button className="bg-[#5963F5] font-bold text-white text-md py-2 px-6 rounded">
                  Signup
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default App;
